import { Tooltip } from "bootstrap";

document.addEventListener('DOMContentLoaded', () => {
  const aktExamEndTime = document.querySelector('meta[name="akt-exam-end-time"]').content;
  const disableElementsBlockedDuringAkt = () => {
    document.querySelectorAll('[data-block-during-akt]:not(.disabled)').forEach(element => {
      element.classList.add('disabled');
      const containerElement = document.createElement('span');

      const parent = element.parentNode;
      parent.replaceChild(containerElement, element);
      containerElement.appendChild(element);

      Tooltip.getOrCreateInstance(containerElement, { title: `You cannot access this feature when an AKT exam is ongoing. Please try again after ${aktExamEndTime}.` });

      element.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
      });
    });
  }

  document.addEventListener("ajax:complete", disableElementsBlockedDuringAkt);
  disableElementsBlockedDuringAkt();
});